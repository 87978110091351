export const constants = {
    // for pagination (this is sent as the pageSize parameter to graphql queries)
    DEFAULT_PAGE_SIZE: 30,
    DEFAULT_PAGE_SIZE_PDB: 100,
    // max page size 500 for exporting all lots in a list
    EXPORT_ALL_LOTS_PAGE_SIZE: 500,
    // for Fine_Art Dec_Art classification type
    FINE_ART_ID: '450',
    DEC_ART_ID: '451',
    ARTNET_AUCTIONS_AUCTION_HOUSE_ID: '425932970',
    // for items to be hidden and items to be labeled coming soon during beta
    PDB_SHOW_PREVIEW_FEATURES: true,
    // cache expiration for selected lots
    LOT_ID_CACHE_TTL: 10 * 60, // 10 minutes
    AUCTIONS_HEADER_CACHE_TTL: 60 * 60, // 1 hour
    // google forms feedback link
    GF_FEEDBACK_URL:
        'https://docs.google.com/forms/d/e/1FAIpQLSf14ovUSU_yxyWQVnLhU3cde7CmjKqOr-XhLFbn4sEK1Hq3gw/viewform?embedded=true',
    PDB_TUTORIAL_VIDEO_URL: 'https://www.youtube.com/embed/1oVIxMxJ4Xw',
    // test video url - https://www.youtube.com/embed/-yFeZekJxZ8
    PDB_ACCESS_RESULT: {
        NO_ACCESS: 'NO_ACCESS',
        PAY_PER_SEARCH: 'PAY_PER_SEARCH',
        UNLIMITED: 'UNLIMITED',
    },
    GLOBAL_SETTINGS_COOKIE: 'globalsettings',
}
