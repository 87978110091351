// Note: the GTM tags use snake_case for the event parameters that are actually sent to GA;
// we are using camelCase in our code for convenience and consistency within our codebase.
interface CtaEventParams {
    // internal name of this CTA used for analytics tracking purposes
    trackingName: string
    // the section of the page where the CTA is located
    pageSection?: string
}

export function defineEventHandlers(analytics: any) {
    return {
        /**
         * Example usage: <Link onClick={genericCtaClick} to="/some-page">...</Link>
         */
        genericCtaClick(
            _: React.MouseEvent<HTMLElement>,
            { trackingName, pageSection }: CtaEventParams
        ) {
            // Note: we have GTM configured to also send the Click Text (the label of the link) by default.
            // We are not currently using the React event object, but it's passed as an argument to this
            // function in case we need it for future tracking features.
            analytics.push({
                event: 'cta_click',
                trackingName,
                pageSection,
            })
        },

        /**
         * Example usage: <Form onSubmit={genericFormSubmit}>...</Form>
         * Or: <Button onClick={genericFormSubmit}>...</Button>
         *
         * (It doesn't have to be a true 'submit' event; what we care about here is if it's a "form submit"
         * from the business perspective)
         */
        genericFormSubmit(
            _: React.MouseEvent<HTMLElement>,
            { trackingName, pageSection }: CtaEventParams
        ) {
            // Note: we have GTM configured to also send the Click Text (the label of the link) by default.
            // We are not currently using the React event object, but it's passed as an argument to this
            // function in case we need it for future tracking features.
            analytics.push({
                event: 'form_submit',
                trackingName,
                pageSection,
            })
        },
    }
}
